import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";

import App from "./App";
import { Helmet } from 'react-helmet';
import { RecoilRoot } from "recoil";

import "antd/dist/antd.min.css";
import "./styles/reset.css";

import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://b4df3de46731b474b36a09a7137b10d5@o4507456368803840.ingest.de.sentry.io/4507749732253776",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <RecoilRoot>
      <Helmet>
          <meta name="viewport" content="min-width=device-width, initial-scale=0.1, maximum-scale=0.1, user-scalable=1" />
      </Helmet>
    <App />
  </RecoilRoot>,
  document.getElementById("root")
);

serviceWorker.unregister();
