import React, { useState, useEffect } from "react";
import {useHistory, useLocation} from "react-router";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import {Layout, Menu, message, Popover, Badge, Avatar, Button} from "antd";
import { MenuOutlined, RightOutlined } from "@ant-design/icons";
import { privateRoutes } from "../routes/routes";
import constant from "../data/constant";
import { useLogout } from "../recoil/auth";
import useSWR from "swr";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const ServiceWrapper = ({ children }) => {
    const history = useHistory();

    const logout = useLogout();
    const location = useLocation();
    const [isVisibleProfilePopover, setIsVisibleProfilePopover] = useState(false);

    const pathName = location.pathname;
    const rootPath = pathName.substring(0, pathName.indexOf("/", 2));
    const pathArray = pathName.split("/")

    const openKey = [];
    openKey[0] = pathArray.length > 2 ? rootPath : pathName;
    if (pathArray.length > 3) {
        openKey[1] = '/' + pathArray[1] + '/' + pathArray[2]
    }

    const companyId = localStorage.getItem('company_id');

    const { data: permissionData, mutate: permissionMutate } = useSWR(`/ceo/permission?company_id=${companyId}`);
    const originalPermissionData = permissionData?.data?.permission;

    useEffect(() => {
        if (originalPermissionData) {
            if (originalPermissionData === 'all') {
            } else {
                if ((originalPermissionData?.board_permission !== 1 && pathName.includes('/board') !== false ||
                    originalPermissionData?.employee_permission !== 1 && pathName.includes('/user') !== false ||
                    originalPermissionData?.work_permission !== 1 && pathName.includes('/attendance') !== false ||
                    originalPermissionData?.additional_permission !== 1 && pathName.includes('/additional') !== false ||
                    originalPermissionData?.day_off_permission !== 1 && pathName.includes('/dayOff') !== false ||
                    originalPermissionData?.document_permission !== 1 && pathName.includes('/document') !== false ||
                    originalPermissionData?.salary_permission !== 1 && pathName.includes('/salary') !== false ||
                    originalPermissionData?.etc_permission !== 1 && pathName.includes('/etc') !== false)) {
                    message.error('권한이 없는 페이지입니다.')
                    history.push('/home')
                }
            }
        }
    }, [pathName, originalPermissionData]);

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <CustomSider
                collapsed={false}
                width={260}
            >
                <LogoBox>
                    <LogoImage src={require("../assets/logo.png")} />
                </LogoBox>
                <CustomMenu
                    mode="inline"
                    activeKey={location.pathname}
                    defaultOpenKeys={openKey}
                    selectedKeys={[location.pathname]}
                >
                    {privateRoutes.map((parentRoute) =>
                        (
                            <>
                                {(originalPermissionData === 'all' ||
                                    Object(parentRoute.sidebar).label == '홈피드' ||
                                    (originalPermissionData?.board_permission === 1 && Object(parentRoute.sidebar).label === '게시판' ||
                                    originalPermissionData?.employee_permission === 1 && Object(parentRoute.sidebar).label === '직원관리' ||
                                    originalPermissionData?.work_permission === 1 && Object(parentRoute.sidebar).label === '근태관리' ||
                                    originalPermissionData?.additional_permission === 1 && Object(parentRoute.sidebar).label === '추가수당' ||
                                    originalPermissionData?.day_off_permission === 1 && Object(parentRoute.sidebar).label === '연차관리' ||
                                    originalPermissionData?.document_permission === 1 && Object(parentRoute.sidebar).label === '문서관리' ||
                                    originalPermissionData?.salary_permission === 1 && Object(parentRoute.sidebar).label === '급여관리' ||
                                    originalPermissionData?.etc_permission === 1 && Object(parentRoute.sidebar).label === '운영설정')) && (
                                    <CustomMenu.Item
                                        key={parentRoute.path}
                                        icon={Object(parentRoute.sidebar).icon}
                                    >
                                        <NavLink
                                            to={parentRoute.path}
                                            className="nav-text"
                                            style={{ fontSize: 16, fontWeight: 'bolder'}}
                                        >
                                            {Object(parentRoute.sidebar).label}
                                        </NavLink>
                                    </CustomMenu.Item>
                                )}
                            </>
                        )
                    )}
                </CustomMenu>
                <ProfileArea onClick={() => history.push('/company/select')}>
                    <div style={{width: '80%', marginRight: '30px'}}>
                        <p style={{
                            fontSize: '20px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '50px',
                            letterSpacing: '-0.9px',
                        }}>
                            {localStorage.getItem("company_name")}
                        </p>
                    </div>
                    <RightOutlined />
                </ProfileArea>
            </CustomSider>
            <Layout
                style={{
                    marginLeft: 260,
                    transition: "all 0.2s",
                    height: "100vh",
                    backgroundColor: "white",
                }}
            >
                <Content style={{ margin: "24px 16px 30px", overflow: "initial" }}>
                    {children}
                </Content>
            </Layout>
        </Layout>
    );
};

const CustomSider = styled(Sider)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    background: #EEF2F5;
    borderRight: 1px solid #f0f0f0;
  
    .ant-menu-item a {
        color: #959BA0 !important; /* Custom color for unselected menu items */
    }
    
    .ant-menu-item-selected {
        color: #000000 !important; /* Ensure selected item text color */
    }

    
    .ant-menu-light .ant-menu-item-active  {
        color: #000000;
        background: #DCE2E7;
    }
    .ant-menu-inline .ant-menu-item::after {
        color: #000000;
        border-right: 0px;
        height: 55px;
    }
    .ant-menu-item-selected a, .ant-menu-item-selected a:hover {
        color: #000000 !important;
        font-size: 16px;
    }
    
    .ant-menu-inline > .ant-menu-item {
        height: 55px;
    }
`;

const CustomMenu = styled(Menu)`
    background: #EEF2F5;
    
    .ant-menu-item {
        color: #959BA0 !important; /* Custom color for unselected menu items */
    }
    
    .ant-menu-item-selected {
        color: #000000 !important; /* Ensure selected item text color */
    }

`;

const ProfileArea = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 18px;
    background: #EEF2F5;
`;

const LogoBox = styled.div`
  width: 100%;
  padding: 40px;
  padding: 25px 55px 25px 20px;
`;

const LogoImage = styled.img`
    width: 50%;
`;

export default ServiceWrapper;
