import React from "react";
import loadable from "@loadable/component";
//antd에서 아이콘 가져오기
//회원 전용 루트
import { PUBLIC_ROUTE } from "./routes.constants";
import { Redirect, useHistory } from "react-router";

//로그인 없이 이용가능 페이지
export const publicRoutes = [
  //첫시작 로그인으로 설정 => ('/' 해당 url 사용 안함)
  {
    exact: true,
    path: PUBLIC_ROUTE.ROOT,
    component: () => {
      if (localStorage.getItem("token")) {
        return <Redirect to="/home" />
      } else {
        return <Redirect to="/login" />
      }
    },
  },
  {
    exact: true,
    path: PUBLIC_ROUTE.LOGIN,
    component: loadable(() => import("../pages/auth/Login")),
  },
  {
    exact: true,
    path: '/request',
    component: loadable(() => import("../pages/auth/join/Request")),
  },
  {
    exact: true,
    path: '/email/certification/wait',
    component: loadable(() => import("../pages/auth/EmailWait")),
  },
  {
    exact: true,
    path: '/join',
    component: loadable(() => import("../pages/auth/join/Join")),
  },
  {
    exact: true,
    path: '/join/confirm/wait',
    component: loadable(() => import("../pages/auth/ConfirmWait")),
  },
  {
    exact: true,
    path: '/find/password',
    component: loadable(() => import("../pages/auth/findPassword/Index")),
  },
  {
    exact: true,
    path: '/set/password',
    component: loadable(() => import("../pages/auth/findPassword/SetPassword")),
  },
  {
    exact: true,
    path: '/modify/password',
    component: loadable(() => import("../pages/auth/findPassword/ModifyPassword")),
  },
  {
    exact: true,
    path: '/company/select',
    component: loadable(() => import("../pages/auth/SelectCompany")),
  },
  {
    exact: true,
    path: '/email/confirm',
    component: loadable(() => import("../pages/auth/EmailConfirm")),
  },
];

//로그인 시에만 이용가능 페이지
export const privateRoutes = [
  {
    exact: true,
    path: "/home",
    sidebar: {
      icon: <img src={require("../assets/icon/home-05.png")}/>,
      label: "홈피드",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "홈피드",
        },
        component: loadable(() => import("../pages/home/List")),
      },
      {
        exact: true,
        visible: false,
        path: '/payment/billing',
        component: loadable(() => import("../pages/home/Payment")),
      },
    ],
  },
  {
    exact: true,
    path: "/board",
    sidebar: {
      icon: <img src={`/assets/icon/dashboard-square-01.svg`}/>,
      label: "게시판",
    },
    component: loadable(() => import("../pages/board/List")),
  },
  {
    exact: true,
    path: "/user",
    sidebar: {
      icon: <img src={require("../assets/icon/user-multiple.png")}/>,
      label: "직원관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "직원관리",
        },
        component: loadable(() => import("../pages/employee/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/employee/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/attendance",
    sidebar: {
      icon: <img src={require("../assets/icon/calendar-03.png")}/>,
      label: "근태관리",
    },
    component: loadable(() => import("../pages/attendance/List")),
  },
  {
    exact: true,
    path: "/additional",
    sidebar: {
      icon: <img src={require("../assets/icon/plus-sign-square.png")}/>,
      label: "추가수당",
    },
    component: loadable(() => import("../pages/additional/List")),
  },
  {
    exact: true,
    path: "/dayOff",
    sidebar: {
      icon: <img src={require("../assets/icon/calendar-minus-01.png")}/>,
      label: "연차관리",
    },
    component: loadable(() => import("../pages/dayOff/List")),
  },
  {
    exact: true,
    path: "/document",
    sidebar: {
      icon: <img src={require("../assets/icon/google-doc.png")}/>,
      label: "문서관리",
    },
    component: loadable(() => import("../pages/document/List")),
  },
  {
    exact: true,
    path: "/salary",
    sidebar: {
      icon: <img src={require("../assets/icon/money-02.png")}/>,
      label: "급여관리",
    },
    component: loadable(() => import("../pages/salary/List")),
  },
  {
    exact: true,
    path: "/setting",
    sidebar: {
      icon: <img src={require("../assets/icon/setting-01.png")}/>,
      label: "운영설정",
    },
    component: loadable(() => import("../pages/operate/List")),
  },
  {
    exact: true,
    path: "/help",
    sidebar: {
      icon: <img src={require("../assets/icon/help-square.png")}/>,
      label: "도움말",
    },
    component: () => {
      window.open("https://www.notion.so/waveglobals/WAVE-ONE-5aeb7a165af7427aabf6855378b91fca?pvs=4", "_blank", "noopener, noreferrer");
      return <Redirect to="/home" />
    },
  },
];
